@font-face {
	font-family: 'Raleway';
	src: url('../Assets/Fonts/Raleway.ttf');
}

@font-face {
	font-family: 'Phudu';
	src: url('../Assets/Fonts/Phudu.ttf');
}

@font-face {
	font-family: 'Montserrat';
	src: url('../Assets/Fonts/Montserrat.ttf');
}

@keyframes loading {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

:root {
	--color1v1: #00ba96;
	--color1v2: #00836e;
	--color1v1Border: #00a485;
	--color1v2Border: #006758;
	--color1Text: white;

	--color2v1: #ae90bc;
	--color2v2: #a182b0;
	--color2v1Border: #a182b0;
	--color2v2Border: #816090;
	--color2Text: white;

	--inputColor: #cacaca;
	--inputText: black;

	--disabledButton: #8b8b8b;
	--disabledButtonBorder: #6d6d6d;
	--disabledButtonText: white;

	--footerbg: black;
	--footerText: white;

	--headerColor: #ede7ef;
	--headerText: black;
	--navButtonBorder: black;

	--backgroundColor: white;
	--backgroundColorText: black;

	--boxradius: 8px;
}

html {
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}

body {
	margin: 0;
	font-family: 'Raleway', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	transition-duration: 150ms;
}

a {
	text-decoration: none;
	color: black;
}

select {
	outline: none;
	background-color: var(--inputColor);
}

h1,
h2 {
	margin: 0;
}

.Phone {
	font-family: 'Montserrat';
}

.App {
	min-height: 80vh;
}

.Main {
	background-color: var(--backgroundColor);
	color: var(--backgroundColorText);
}

.Logo,
.Logo > img {
	width: 10vh;
}

.Button {
	width: fit-content;
}

.Button > button {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: var(--color1v1);
	font-size: 1em;
	padding: 0.6em 1.2em;
	border: 2px solid var(--color1v1Border);
	color: var(--color1Text);
	border-radius: var(--boxradius);
}

.ButtonDisabled > button {
	background-color: var(--disabledButton);
	color: var(--disabledButtonText);
	border: 2px solid var(--disabledButtonBorder);
	cursor: not-allowed;
}

.ButtonDisabled > button:hover {
	background-color: var(--disabledButton);
}

.Header > *:last-child {
	margin-left: auto;
}

.DesktopHomePage {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	font-size: 3em;
	font-weight: bold;
	background: white;
	text-align: center;
}

.LoginPage {
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 2vh;
	background: white;
}

.LoginPageMain {
	margin-top: 10vh;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	gap: 2vh;
}

.LoginPageMain > h1 {
	text-align: center;
}

.LoginPageMain > img {
	height: 10vh;
	margin-bottom: 10vh;
}

.inputField {
	box-sizing: content-box;
	border: none;
	background-color: var(--inputColor);
	padding: 0.25em 0.5em;
	font-size: 1.2em;
	width: 50vw;
	border-radius: var(--boxradius);
	color: var(--inputText);
}

.inputField:focus {
	outline: none;
}

.comment {
	font-family: 'Raleway';
	font-size: 0.8em;
	width: 100%;
	box-sizing: border-box;
	min-height: 10vh;
	resize: vertical;
}

.NoAccount {
	font-size: 1em;
	display: flex;
	align-items: center;
	text-align: justify;
}

.NoAccount > a {
	text-decoration: underline;
	padding: 1em;
	cursor: pointer;
}

.Footer {
	width: 80%;
	padding: 5vh 10%;
	min-height: 20vh;
	background-color: var(--footerbg);
	color: var(--footerText);
	position: relative;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 3vh;
}

.FooterElement {
	font-size: 1em;
	font-family: 'Montserrat';
}

.FooterElement > a {
	color: var(--footerText);
}

.Calling {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.User {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: var(--color2v2);
	color: var(--color2Text);
	padding: 2%;
	border-radius: 0 0 0 var(--boxradius);
	border-left: 2px solid var(--color2v2Border);
	border-bottom: 2px solid var(--color2v2Border);
	width: 50%;
	height: 20vh;
	justify-content: space-evenly;
}

.User > a {
	padding: 3vw;
}

.CallActions {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width: 50%;
	gap: 3vh;
}

.UserName {
	text-align: center;
	word-break: break-all;
	font-size: 1em;
}

.User > a {
	display: flex;
	flex-direction: column;
	gap: 0.5em;
}

.User > a > div {
	color: white;
	font-size: 1em;
	white-space: nowrap;
	text-align: center;
}

.CallingHeader > h2,
.Calling > h2 {
	font-size: 1em;
	text-align: center;
	text-align: center;
	flex-grow: 1;
}

.CallingHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 25vh;
	height: fit-content;
}

.CallingError {
	display: flex;
	width: 100%;
	margin-top: 20vh;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 1.7em;
	font-weight: bold;
}

.Script {
	font-size: 1em;
	padding: 2em;
	text-align: justify;
	line-height: 1.5em;
	white-space: pre-wrap;
}

.Script > h3 {
	text-align: center;
}

.E404 {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
	height: 60vh;
}

.E404 > h1 {
	text-align: center;
	font-size: 3em;
	padding: 0 2em;
}

.ButtonIcon {
	width: 14px;
}

.CallingEnded {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.CallingEnded > h2 {
	text-align: center;
	font-size: 1.5em;
}

.UserEnded {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--color2v2);
	min-width: 40%;
	padding: 1em;
	margin-top: 1em;
	color: var(--color2Text);
	border-radius: var(--boxradius);
}

.UserEnded > div {
	font-size: 1em;
}

.UserNameEnded {
	font-size: 1.2em;
	font-weight: 400;
}

.CallingEndContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.CallingButtons {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1em;
	margin-bottom: 2vh;
}

.Header {
	position: sticky;
	top: 0;
	width: 98%;
	padding: 1vh 1%;
	display: flex;
	align-items: center;
	justify-content: left;
	gap: 2vw;
	background-color: var(--headerColor);
	z-index: 2;
	height: calc(6vh + 1px);
}

.Logo,
.Logo > img {
	height: 6vh;
}

.AreaName {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: bold;
	font-size: 1.5em;
	font-family: 'Phudu';
	font-weight: 500;
	color: var(--headerText);
}

.NavMenu {
	transition-duration: 250ms;
	transform: translateY(-92vh);
	background-color: var(--headerColor);
	position: fixed;
	top: 8vh;
	width: 100%;
	height: 92vh;
	z-index: 1;
	display: flex;
	flex-direction: column;
}

.NavMenuButton {
	width: 100%;
	padding: 3vh 2vh;
	font-weight: 600;
	font-size: 1.3em;
	border-bottom: solid 1px var(--navButtonBorder);
	color: var(--headerText);
}

.FirstNavMenuButton {
	border-top: solid 1px var(--navButtonBorder);
}

.LastNavMenuButton {
	border-bottom: none;
}

.expanded {
	transform: translateY(1px);
}

.MenuIcon {
	width: 8vw;
	height: 8vw;
	padding: 2vw;
	margin-right: 5vw;
	margin-left: auto;
	cursor: pointer;
	color: var(--headerText);
}

.RedButton > button {
	background-color: var(--color2v2);
	border: 2px solid var(--color2v2Border);
	color: var(--color2Text);
}

.Dashboard {
	padding: 2vh 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2vh;
}

.Dashboard {
	text-align: center;
}

.AreaSelector {
	width: 90%;
	margin: 6vh 0;
	font-size: 1em;
	display: flex;
	flex-direction: column;
	gap: 1vh;
}

.AreaSelector > h2 {
	font-size: 1.5em;
}

.SettingsPage {
	display: flex;
	flex-direction: column;
	margin: 2% 5%;
	gap: 2vh;
}

.SettingsPage > div > b {
	font-weight: 600;
}

.Progress {
	font-size: 1.1em;
	padding: 0 5vw;
	text-align: center;
}

.JoinArea {
	font-size: 1em;
	padding: 0.5em;
	color: var(--backgroundColorText);
}

.CallingHoursError {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.5em;
	text-align: center;
	font-weight: bold;
	gap: 2vh;
}

.CallingHoursError > h4 {
	margin-bottom: 0;
}

.CallingHoursError > div {
	font-size: initial;
}

.ScoreBoard {
	display: flex;
	flex-direction: column;
	gap: 1vh;
	width: 80vw;
}

.ScoreBox {
	padding: 2vh 2vh;
	background-color: var(--inputColor);
	display: flex;
	justify-content: space-between;
}

.YourSelf {
	background-color: var(--color1v1);
	color: var(--color1Text);
}

.CallHistory {
	text-align: center;
	width: 100%;
	padding: 3vh 0;
	background-color: var(--color1v1);
	color: var(--color1Text);
	border-top: 2px solid var(--color1v1Border);
	border-bottom: 2px solid var(--color1v1Border);
	margin-top: 2px;

	& > h3 {
		margin-top: 0;
	}
}

.NoCall {
	text-align: center;
	padding: 3vh 0;
	width: 100%;
	background-color: var(--color1v1);
	color: var(--color1Text);
	border-top: 2px solid var(--color1v1Border);
	border-bottom: 2px solid var(--color1v1Border);
	margin-top: 2px;
}

#theme {
	width: 60vw;
}

.Loader {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 5;
	background: color-mix(in srgb, var(--color1Text) 70%, transparent);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-weight: bold;
	font-size: 2em;

	& > svg {
		animation-name: loading;
		animation-duration: 2000ms;
		animation-timing-function: linear;
		animation-iteration-count: infinite;

		& > circle {
			stroke: var(--color1v1);
		}
	}
}
